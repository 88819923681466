<script>

import BusinessHours from 'vue-business-hours';
import { required } from "vuelidate/lib/validators";
import moment from 'moment-timezone';
import Multiselect from "vue-multiselect";

export default {
  components: {
    BusinessHours,
    Multiselect
  },
  data() {
    return {
    localization: {
      switchOpen: this.$t("availability.availability_switchOpen"),
      switchClosed: this.$t("availability.availability_switchClosed"),
      placeholderOpens: this.$t("availability.availability_placeholderOpens"),
      placeholderCloses: this.$t("availability.availability_placeholderCloses"),
      addHours: this.$t("availability.availability_addHours"),
      open: {
        invalidInput: this.$t("availability.availability_open_invalidInput"),
        greaterThanNext: this.$t("availability.availability_open_greaterThanNext"),
        lessThanPrevious: this.$t("availability.availability_open_lessThanPrevious"),
        midnightNotLast: this.$t("availability.availability_open_midnightNotLast"),
      },
      close: {
        invalidInput: this.$t("availability.availability_close_invalidInput"),
        greaterThanNext: this.$t("availability.availability_close_greaterThanNext"),
        lessThanPrevious: this.$t("availability.availability_close_lessThanPrevious"),
        midnightNotLast: this.$t("availability.availability_close_midnightNotLast"),
      },
      t24hours: this.$t("availability.availability_t24hours"),
      midnight: this.$t("availability.availability_midnight"),
      days: {
        monday: this.$t("availability.availability_days_monday"),
        tuesday: this.$t("availability.availability_days_tuesday"),
        wednesday: this.$t("availability.availability_days_wednesday"),
        thursday: this.$t("availability.availability_days_thursday"),
        friday: this.$t("availability.availability_days_friday"),
        saturday: this.$t("availability.availability_days_saturday"),
        sunday: this.$t("availability.availability_days_sunday"),
        newYearsEve: this.$t("availability.availability_days_newYearsEve"),
        newYearsDay: this.$t("availability.availability_days_newYearsDay"),
        martinLutherKingJrDay: this.$t("availability.availability_days_martinLutherKingJrDay"),
        presidentsDay:  this.$t("availability.availability_days_presidentsDay"),
        easter: this.$t("availability.availability_days_easter"),
        memorialDay: this.$t("availability.availability_days_memorialDay"),
        independenceDay: this.$t("availability.availability_days_independenceDay"),
        fourthOfJuly: this.$t("availability.availability_days_fourthOfJuly"),
        laborDay: this.$t("availability.availability_days_laborDay"),
        columbusDay: this.$t("availability.availability_days_columbusDay"),
        veteransDay: this.$t("availability.availability_days_veteransDay"),
        thanksgiving: this.$t("availability.availability_days_thanksgiving"),
        christmasEve: this.$t("availability.availability_days_christmasEve"),
        christmas: this.$t("availability.availability_days_christmas"),
      }
    },
    businessHours : null,
    submitted: false,
    requiredError:false,
    timezones : [],
    tzone : null,
    exception:null
    };
  },
  methods: {
    validateRequiredAvailability(){
      this.requiredError = false;
      const keys = ['monday','tuesday','wednesday','thursday','friday','saturday', 'sunday'];

      keys.forEach((day)=>{
        if(this.businessHours[day] && this.businessHours[day].length >0){
          this.businessHours[day].forEach((hour)=>{
            if(hour.isOpen && (hour.open=='' || hour.close=='')){
              this.requiredError = true;
              return;
            }
          });
        }
      });
    },
    updatedHours(info){
      const keys = ['monday','tuesday','wednesday','thursday','friday','saturday', 'sunday'];

      keys.forEach((day)=>{
        if(info[day] && info[day].length >0){
          let hour = info[day][0];
            if(hour.isOpen && hour.open==''){
              hour.open = '24hrs';
              hour.close ='24hrs';
          }
          
        }
        
      })
    },
    onCreateAvailability(){
      this.submitted = true;
			this.$v.$touch();

      this.validateRequiredAvailability();
      
			if (this.$v.$invalid || this.requiredError) {
				return false;
			}

      this.availability.timezone = this.tzone;
      this.availability.availability = this.businessHours;

      return true;
    },

    addException(){

      if(this.exception && this.exception !=''){
        if(!this.availability.exceptions)
          this.availability.exceptions = []

        if(this.availability.exceptions.indexOf(this.exception)==-1)
          this.availability.exceptions.push(`${this.exception.split('-')[2]}-${this.exception.split('-')[1]}-${this.exception.split('-')[0]}`);
        
        this.exception = null;
      }
      
    },

    removeException(ex){
      this.availability.exceptions = this.availability.exceptions.filter(e=> e !=ex);
    }


  },
  validations: {
    availability: {
      name: {
        required,
      }
    },
  },
  mounted() {
    
    this.timezones= moment.tz.names();
    this.tzone = moment.tz.guess();
    
    if(this.availability && this.availability.availability){
      this.businessHours = this.availability.availability;
      this.tzone = this.availability.timezone;
    }
    else 
      this.businessHours = {
      sunday: [
        {
          open: '0900',
          close: '1700', 
          id: '1',
          isOpen: false
        }
      ],
      monday: [
        {
          open: '0900',
          close: '1700',
          id: '2',
          isOpen: true
        }
      ],
      tuesday: [
        {
          open: '0900',
          close: '1700',
          id: '3',
          isOpen: true
        },
        
      ],
      wednesday: [
        {
          open: '0900',
          close: '1700',
          id: '4',
          isOpen: true
        }
      ],
      thursday: [
        {
          open: '0900',
          close: '1700',
          id: '5',
          isOpen: true
        }
      ],
      friday: [
        {
          open: '0900',
          close: '1700',
          id: '6',
          isOpen: true
        },        
      ],
      saturday: [
        {
          open: '0900',
          close: '1700',
          id: '7',
          isOpen: false
        }
      ]
    }
  },
  props: {
    availability:{
      type: Object,
    },
  },
}

</script>

<template>
  <div>
    <div class="row">
        <div class="col flex">
          <div class="mb-3">
            <label for="availabilityName">{{this.$t("availability.name")}}*</label>
            <input
              id="availabilityName"
              v-model="availability.name"
              name="name"
              type="text"
              class="form-control"
              :class="{'is-invalid': submitted && $v.availability.name.$error}"
            />
            <div
              v-if="submitted && !$v.availability.name.required"
              class="invalid-feedback">
                {{this.$t("company.name_required")}}
            </div>
          </div>
        </div>
    </div>

    <div class="row">
      <div class="col flex">
          <div class="mb-3">
            <label>Zona horaria*</label>
            <multiselect 
              v-model="tzone"
              :options="timezones"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              :multiple="false"
              :showNoResults="false"
              :allow-empty="false">
            </multiselect>
          </div>
      </div>
    </div>
    <div class="row">
        <div class="col flex">
          <div class="mb-3">
            <label>{{this.$t("availability.title")}}*</label>
            <business-hours 
              :days="businessHours" 
              color='#556ee6' 
              :localization="localization" 
              type="select" 
              :timeIncrement=15
              @updated-hours="updatedHours">
            </business-hours>
          </div>
          <div v-if="requiredError" class="invalid-feedback">{{this.$t("availability.availability_required")}}</div>
        </div>
    </div>

    <div class="row">
        <div class="col-6 flex">
          <div class="mb-3">
            <label>{{this.$t("availability.exceptions")}}</label>
            <div class="input-group">
              <b-form-input
                id="date"
                v-model= "exception"
                placeholder="dd/MM/YYYY"
                type="date"
              ></b-form-input>
              <button
                class="btn btn-primary"
                type="button"
                @click="addException">
                +
              </button>
            </div>
          </div>
        </div>
        <div>
          <ul class="list-style">
            <li v-for="ex in availability.exceptions" :key="ex"> {{ex}} <i v-on:click="removeException(ex)" class="bx bx-trash"/></li>
          </ul>
        </div>
    </div>
  </div>
</template>

<style scoped>
.invalid-feedback{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f46a6a;
    display: block;
}
</style>